import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Подключаем стили для телефона
import '../allCss/Registr.css'; // Подключаем стили

function Register() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [surname, setSurname] = useState('');
  const [name, setName] = useState('');
  const [patronymic, setPatronymic] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();

    // Проверка, что все поля заполнены
    if (!username || !email || !surname || !name || !patronymic || !mobilePhone || !birthDate || !password) {
      setError('Все поля обязательны для заполнения');
      return;
    }

    // Проверка мобильного телефона (должен быть российский номер)
    const phoneRegex = /^[7]\d{10}$/;
    if (!phoneRegex.test(mobilePhone)) {
      setError('Введите корректный российский номер телефона');
      return;
    }

    // Проверка даты рождения (разумный год рождения)
    const birthYear = new Date(birthDate).getFullYear();
    const currentYear = new Date().getFullYear();
    if (birthYear > currentYear || birthYear < 1900) {
      setError('Введите корректную дату рождения');
      return;
    }

    // Отправка данных на сервер
    try {
      const response = await axios.post(`${process.env.REACT_APP_USERS_URL}/register`, {
        email,
        username,
        surname,
        name,
        patronymic,
        mobile_phone: `+${mobilePhone}`, // Добавляем "+" к номеру
        birth_date: new Date(birthDate).toISOString(), // Преобразование даты в ISO формат
        password_hash: password, // Передаём пароль как plain text в поле "password_hash"
      });
      
      if (response.data) {
        navigate('/login'); // Перенаправляем на страницу входа
      }
    } catch (error) {
      setError('Ошибка регистрации, попробуйте снова');
    }
  };

  return (
    <div className="register-page">
      <form onSubmit={handleRegister} className="register-form">
        <img src="/your-logo-path.png" alt="Logo" />
        <h2>Регистрация</h2>
        {error && <p className="error-message">{error}</p>}
        <input
          type="text"
          placeholder="Имя пользователя"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Фамилия"
          value={surname}
          onChange={(e) => setSurname(e.target.value)}
        />
        <input
          type="text"
          placeholder="Имя"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Отчество"
          value={patronymic}
          onChange={(e) => setPatronymic(e.target.value)}
        />
        <PhoneInput
          country={'ru'}
          value={mobilePhone}
          onChange={setMobilePhone}
          placeholder="Мобильный телефон"
          onlyCountries={['ru']}
        />
        <input
          type="date"
          max={`${new Date().toISOString().split('T')[0]}`} // Ограничиваем ввод будущих дат
          placeholder="Дата рождения"
          value={birthDate}
          onChange={(e) => setBirthDate(e.target.value)}
        />
        <input
          type="password"
          placeholder="Пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Зарегистрироваться</button>
      </form>
    </div>
  );
}

export default Register;
