import React, { useEffect } from 'react';
import { Route, Routes, Link, useNavigate, useLocation } from 'react-router-dom';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import TransportCatalog from './components/TransportCatalog';
import './allCss/Main.css';

function App() {
  const token = localStorage.getItem('token'); // Проверяем наличие токена
  const navigate = useNavigate();
  const location = useLocation(); // Получаем текущий путь

  useEffect(() => {
    if (!token && location.pathname !== '/login' && location.pathname !== '/register') {
      navigate('/login'); // Перенаправляем на страницу входа, если нет токена и не на странице входа/регистрации
    }
  }, [token, navigate, location]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/login');
  };

  return (
    <div className="app-container">
      <main className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/dashboard" element={token ? <Dashboard /> : <Login />} />
          <Route path="/map" element={<TransportCatalog />} />
        </Routes>
      </main>

      {/* Черная плашка отображается, если не на странице входа или регистрации */}
      {location.pathname !== '/login' && location.pathname !== '/register' && (
        <div className="bottom-bar">
          <button className="button-profile" onClick={() => navigate('/dashboard')}>
            Профиль
          </button>
          <Link className="catalog-link" to="/">Каталог</Link>
          <button className="logout-btn" onClick={handleLogout}>Выйти</button>
        </div>
      )}
    </div>
  );
}

function Home() {
  return (
    <div className="home-page">
      <h1 className='logo-text'>4UКотка</h1>
      <TransportCatalog onTransportBooked={() => {}} />
    </div>
  );
}

export default App;
