import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import '../allCss/Login.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
  
    const loginData = { email, password }; 

    // Логируем API URL перед отправкой запроса
    console.log("API URL для логина:", `${process.env.REACT_APP_USERS_URL}/login`); 

    try {
      console.log('Отправка данных для логина:', loginData); // Логируем отправляемые данные
      const response = await axios.post(`${process.env.REACT_APP_USERS_URL}/login`, loginData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      
      console.log('Ответ от сервера:', response.data); // Логируем ответ сервера

      if (response.data && response.data.token) {
        console.log('Получен токен:', response.data.token); // Логируем полученный токен
        localStorage.setItem('token', response.data.token); // Сохраняем токен в localStorage
        console.log('Токен сохранён в localStorage'); // Подтверждение сохранения токена

        await getUserData(); // Вызываем функцию для получения данных пользователя
        navigate('/'); // Перенаправляем на главную страницу
      } else {
        setError('Неверный email или пароль');
        console.error('Ошибка логина: токен не получен'); // Логируем, если токен не получен
      }
    } catch (error) {
      console.error("Ошибка логина:", error.response ? error.response.data : error.message); // Логируем ошибку
      setError('Неверный email или пароль');
    }
  };
  
  const getUserData = async () => {
    const token = localStorage.getItem('token'); // Получаем токен из localStorage
    if (!token) {
      console.error("Токен не найден в localStorage"); // Логируем, если токен не найден
      return;
    }

    console.log('Используем токен для получения данных пользователя:', token); // Логируем используемый токен

    try {
      const response = await axios.get(`${process.env.REACT_APP_USERS_URL}/users`, {
        headers: {
          'Authorization': `Bearer ${token}` // Передаем токен в заголовке
        }
      });

      console.log("Данные пользователя:", response.data); // Логируем данные пользователя
    } catch (error) {
      console.error("Ошибка получения данных пользователя:", error.response ? error.response.data : error.message); // Логируем ошибку
    }
  };

  return (
    <div className="login-page">
      <div className="logo-container">
        <img src="your-logo-path.png" alt="Logo" className="logo" />
        <h1 className="logo-text">4Ukотка</h1>
      </div>
      <form onSubmit={handleLogin} className="login-form">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          placeholder="Введите ваш email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="password">Пароль</label>
        <input
          type="password"
          id="password"
          placeholder="Введите ваш пароль"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit" className="login-btn">Вход</button>
        {error && <p className="error-message">{error}</p>}
      </form>

      <div className="register-link-container">
        <p>Нет аккаунта?</p>
        <Link to="/register" className="register-link">Зарегистрироваться</Link>
      </div>
    </div>
  );
}

export default Login;
