import React, { useEffect, useCallback, useState } from 'react';
import { io } from 'socket.io-client';
import axios from 'axios';
import YandexMap from './maps/YandexMap';
import '../allCss/Main.css'; // Подключаем CSS

// Импортируем изображения
import irbis2016 from '../images/irbis2016.jpg';
import trekol2018 from '../images/trekol2018.jpg';
import sherppro from '../images/sherppro.jpg';
import CanAmO2015 from '../images/CanAmO2015.jpg';
import CFMotoX82018 from '../images/CFMotoX82018.jpg';
import HondaRX500Foreman2013 from '../images/HondaRX500Foreman2013.jpg';
import YamahaGrizzly from '../images/YamahaGrizzly.jpg';
import ArcticCat from '../images/ArcticCat.jpg';
import BRPLynx69Ranger2019 from '../images/BRPLynx69Ranger2019.jpg';
import PolarisRMK800 from '../images/PolarisRMK800.jpg';

const TransportCatalog = ({ onTransportBooked }) => {
  const [transports, setTransports] = useState([]);
  const [selectedCoords, setSelectedCoords] = useState(null); // Для карты
  const [selectedTransport, setSelectedTransport] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // Добавляем состояние для модального окна

  const imageMap = {
    'Вездеход Газ-34039 Ирбис 2016': irbis2016,
    'Вездеход Трекол 39294 2018': trekol2018,
    'Вездеход Шерп Pro 2020': sherppro,
    'Квадроцикл Can-Am Outlander 1000R 2015': CanAmO2015,
    'Квадроцикл CFMoto X8 H.O. EPS 2018': CFMotoX82018,
    'Квадроцикл Honda TRX500 Foreman 2013': HondaRX500Foreman2013,
    'Квадроцикл Yamaha Grizzly 700 2010': YamahaGrizzly,
    'Снегоход Arctic Cat M8000 2017': ArcticCat,
    'Снегоход BRP Lynx 69 Ranger 2019': BRPLynx69Ranger2019,
    'Снегоход Polaris RMK 800 2012': PolarisRMK800,
  };

  // Подключение к WebSocket
  useEffect(() => {
    const socket = io('http://localhost:5000');
    socket.on('transportUpdated', (updatedTransport) => {
      setTransports((prevTransports) => {
        if (updatedTransport.status === 'booked') {
          return prevTransports.filter((transport) => transport._id !== updatedTransport._id);
        } else if (updatedTransport.status === 'available') {
          return [...prevTransports, updatedTransport];
        }
        return prevTransports;
      });
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  // Загрузка транспорта
  const fetchTransports = useCallback(() => {
    axios.get('http://localhost:5000/api/transports')
      .then(({ data }) => {
        const availableTransports = data.filter(transport => transport.status === 'available');
        setTransports(availableTransports);
      })
      .catch((error) => {
        console.error('Ошибка при загрузке транспорта:', error);
      });
  }, []);

  useEffect(() => {
    fetchTransports();
  }, [fetchTransports]);

  // Функция бронирования
  const handleBooking = async (transportId) => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const userId = user ? user._id : null;

      if (!userId) {
        alert('Не удалось определить пользователя');
        return;
      }

      const response = await axios.post(`http://localhost:5000/api/transports/book/${transportId}`, { userId });
      const bookedTransport = response.data.transport;
      fetchTransports();
      onTransportBooked(bookedTransport); // Передаем данные о бронировании
      alert('Транспорт успешно забронирован');
    } catch (error) {
      console.error('Ошибка при бронировании:', error);
      alert('Ошибка при бронировании транспорта');
    }
  };

  // Обработка клика по адресу для показа карты
  const handleAddressClick = (coords, transport) => {
    setSelectedCoords(coords);
    setSelectedTransport(transport);
    setIsModalOpen(true); // Открываем модальное окно
  };

  const closeModal = () => {
    setIsModalOpen(false); // Закрываем модальное окно
  };

  return (
    <div className="catalog-container">
      <div className="transport-catalog">
        {transports.map((transport) => (
          <div key={transport._id} className="transport-card">
            <img src={imageMap[transport.name]} alt={transport.name} />
            <h3 className="transport-name">{transport.name}</h3>
            <p className="transport-address" onClick={() => handleAddressClick(transport.coords, transport)}>
              {transport.coords.join(', ')}
            </p>
            <button onClick={() => handleBooking(transport._id)}>Забронировать</button>
          </div>
        ))}
      </div>

      {isModalOpen && selectedCoords && selectedTransport && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>X</button>
            <YandexMap coords={selectedCoords} transport={selectedTransport} onTransportBooked={handleBooking} />
          </div>
        </div>
      )}
    </div>
  );
};

export default TransportCatalog;
