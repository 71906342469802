import React, { useRef, useEffect } from 'react';

const loadYandexMaps = () => {
  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById('yandex-maps-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.id = 'yandex-maps-script';
      script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=08ba13f5-9c1f-4096-a883-a491a452d2c3';
      script.onload = () => {
        if (window.ymaps) {
          resolve(window.ymaps);
        } else {
          reject('ymaps не определён');
        }
      };
      script.onerror = () => reject('Ошибка загрузки скрипта ymaps');
      document.head.appendChild(script);
    } else {
      if (window.ymaps) {
        resolve(window.ymaps);
      } else {
        existingScript.addEventListener('load', () => {
          if (window.ymaps) {
            resolve(window.ymaps);
          } else {
            reject('ymaps не определён после загрузки существующего скрипта');
          }
        });
      }
    }
  });
};

const YandexMap = ({ coords, transport, onTransportBooked }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    loadYandexMaps()
      .then((ymaps) => {
        ymaps.ready(() => {
          if (!mapRef.current) {
            mapRef.current = new ymaps.Map('map', {
              center: [64.7333, 177.5167],
              zoom: 13,
            });
          }

          if (coords && transport) {
            mapRef.current.geoObjects.removeAll();

            const placemark = new ymaps.Placemark(coords, {
              balloonContent: `<div>Транспорт находится здесь<br/><button id="book-transport-${transport._id}" class="booking-button">Забронировать</button></div>`,
            });

            mapRef.current.geoObjects.add(placemark);
            mapRef.current.setCenter(coords, 13);

            placemark.events.add('balloonopen', () => {
              const button = document.getElementById(`book-transport-${transport._id}`);
              if (button) {
                button.addEventListener('click', () => onTransportBooked(transport._id));
              }
            });
          }
        });
      })
      .catch((error) => {
        console.error('Ошибка при загрузке API Яндекс.Карт:', error);
      });
  }, [coords, transport, onTransportBooked]);

  return <div id="map" style={{ height: '400px', width: '100%' }}></div>;
};

export default YandexMap;
